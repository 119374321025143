
.blob-parent-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.blob-wrapper{
    text-align:center;
    position: absolute;
    width: 100%;
}

.blob {
    margin: 0 auto;
    width: 100%;
    height:auto;
}

.blob-alt {
    transform: scale(-.8, -.8) rotate(45deg);
    z-index: 1;
}

.svg {
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
}