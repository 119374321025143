#content-home-container {
    position: absolute;
    transition: 500ms all ease-in-out;
    top: 0%;
    left: 0%;
    filter: opacity(1);
    display: flex;
}

.home-child {
    position: relative;
    height: 100% !important;
}

#home-left-container {
    width: 600px;
}

#home-horizontal-line {
    min-width: 2px;
    /* height: calc(100% !important - 3.8rem); */
    background-color: #04E762;
    /* background-color: white; */
    /* box-shadow: 0 0 30px 30px rgb(63, 63, 63); */
    /* margin: 20px; */
}

#home-right-container {
    flex-grow: 1;
    /* overflow: hidden; */
    /* display: flex;
    justify-content: center;
    align-items: center; */
}



#content-home-title-container {
    position: absolute;
    width: fit-content;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

#home-name-container {
    display: flex;
}

#content-home-title-container h1 {
    color: rgb(255, 255, 255);
    font-size: 10rem;
    z-index: 100;
    /* margin-left: 100px; */
}

#content-home-title-container h1:first-child {
    margin-right: 40px;
}

#content-home-title-container h2 {
    position: absolute;
    /* color: #1c1c1c; */
    color: #04E762;
    font-size: 2rem;
    font-weight: 500;
    bottom: 0;
    right: 0;
    transform: translateY(50%);
    font-family: 'Montserrat', sans-serif;
}

#content-home-container.move-off-up {
    top: -100vh;
    filter: opacity(0);
}

#content-home-container.move-off-down {
    top: 100vh;
    filter: opacity(0);
}

/* #home-scroll-box {
    height: 100%;
    width: 400px;
    background: white;
    overflow-y: scroll;
} */

@media (max-width: 1100px) {

    .home-child {
        position: absolute;
        height: 100%;
        width: 100%;
    }
    #home-right-container {
        width: 70%;
        right: 0px;
    }
    #home-left-container {
        width: 100%;
    }
}

#home-profile-pic {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

/* =========================THEME CHANGE STUFF===============================*/

.nav-home-theme .main-nav-side{
    border-right: 2px solid black;
    border-right: 2px solid #04E762;
}

.nav-home-theme .main-nav-item-container{
    border-bottom: 2px solid black;
    border-bottom: 2px solid #04E762;
}

/* .nav-home-theme .main-nav-item-container:hover {
    background: #04E762;
} */

.nav-home-theme .nav-container-active {
    background: #04E762;
}

.nav-home-theme .nav-icon{
    color: #04E762;
    color: rgb(255, 255, 255);
}

@media (max-width: 1500px) {
    #home-name-container {
        flex-direction: column;
    }
    #home-name-container h1{
        text-align: center;
        margin-right: 0;
    }
    #content-home-title-container h1:first-child {
        margin-right: 0;
    }
    #content-home-title-container h2{
        top: 50%;
        left:0;
        width: 100%;
        height: fit-content;
        transform: translate(0, -70%);
        text-align: center;
        font-size: 1.5rem;
        /* font-weight: bolder; */
        /* right: 50%; */

        /* color: rgb(255, 255, 255);
        font-weight: bolder; */
    }

}


@media (max-width: 900px) {

    #home-name-container h1{
        font-size: 6rem;
    }
    #content-home-title-container h2{
        font-size: 1.2rem;
    }

}

@media (max-width: 500px) {

    #home-name-container h1{
        font-size: 3rem;
    }
    #content-home-title-container h2{
        font-size: .7rem;
    }

}