@keyframes move-clouds-in {
  from {
    opacity: 0;
    left: 100%;
  }
  to {
    opacity: 0.2;
    left: 0;
  }
}

#content-work-container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;
}

#background-clouds {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  left: 100%;
  transform: translateY(-50%);
  filter: grayscale();
  object-fit: cover;
  opacity: 0;
  animation: move-clouds-in 1.3s ease-in-out 0.3s 1 forwards;
}

.content-work-child {
  flex-grow: 1;
  height: 100%;
  position: relative;
  overflow: hidden;
}

#content-work-left {
  max-width: 600px;
  min-width: 600px;
  height: 100%;
  position: relative;
  transform: translateY(-100%);
  animation: slide-in-top 1s ease-in-out 0.3s 1 forwards;
  border: 2px solid rgb(255, 255, 255);
  margin-left: 25px;
}

#content-work-left-inner {
  position: absolute;
  height: 100%;
  /* width: 100%; */
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  background-color: white;
}

#content-work-left img {
  /* flex: 1 1 0; */
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left;
  /* filter: grayscale(); */
  box-shadow: 0 -10px 20px rgba(0, 0, 0, 0.5);
  border-top: 3px solid rgb(255, 255, 255);
}

#content-work-left img:first-child {
  border: none;
}

.content-work-left-child {
  height: 100%;
  width: 100%;
}

.content-work-right-child {
  position: relative;
  height: 50%;
  width: 100%;
}

#content-work-right {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translateX(100%);
  /* border-left: 1px solid rgb(49, 49, 49); */
  animation: slide-in-right 1s ease-in-out 0.3s 1 forwards;
}

#content-work-detail-container-inner {
  position: absolute;
  height: 100%;
  width: 100%;
  color: black;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.detail-child {
  width: 50%;
  height: 100%;
  /* border: 1px solid red; */
}

.y-divider {
  width: 2px;
  height: calc(100% - 4rem);
  /* height: 100%; */
  background: rgb(148, 148, 148);
}

.x-divider {
  max-height: 2px;
  min-height: 2px;
  max-width: calc(100% - 4rem);
  display: none;
  background: rgb(148, 148, 148);
}

#content-work-detail-left {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

#content-work-detail-left p {
  font-weight: 600;
  text-align: center;
  line-height: 1.5rem;
}

#content-work-detail-left p span {
  color: #04e762;
  font-size: 2rem;
  font-weight: 900;
  font-family: "Cabin Sketch", cursive;
}

#content-work-detail-right {
  /* background: black; */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 2rem;
}

.tech-item-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: rgb(50, 164, 199);
}

.tech-icon {
  height: 60px;
  width: 60px;
  color: black;
}

#content-work-title-container {
  background: linear-gradient(45deg, #04e762, rgb(50, 164, 199));
  display: flex;
  justify-content: center;
  align-items: center;
}

#content-work-title-inner {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#content-work-title-container h2 {
  font-size: 8rem;
  color: rgb(0, 0, 0);
}

.project-button-container {
  position: absolute;
  top: 75%;
  border-radius: 10px;
  overflow: hidden;
  transform: translateY(-50%);
  display: flex;
  column-gap: 2px;
  z-index: 6;
}

.project-button {
  background: transparent;
  width: 100px;
  height: 50px;
  font-weight: 900;
  font-size: 1rem;
  color: white;
  transition: 0.3s all;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
  border-radius: 10px;
}

.project-button:hover {
  background: white;
  color: #04e762;
  color: black;
}

.work-descriptor {
  position: absolute;
  color: #04e762;
  top: 0;
  left: 25%;
  transform: translate(-50%, -60%);
  height: 50px;
  border-radius: 30px;
  background: white;
  padding: 0 15px;
  display: flex;
  align-items: center;
  font-weight: 900;
}

.work-descriptor:last-of-type {
  left: 75%;
  color: rgb(50, 164, 199);
}

.alt-section-background.work-section-background {
  position: absolute;
  background: white;
  opacity: 1;
  z-index: -1;
}

/* --------------------------MOBILE STUFF --------------------------------------- */
@media screen and (max-width: 1500px) {
  #content-work-left {
    max-width: 450px;
    min-width: 450px;
  }

  .tech-icon {
    height: 50px;
    width: 50px;
  }
}

@media screen and (max-width: 1300px) {
  #content-work-detail-left p {
    font-size: 0.8rem;
  }

  #content-work-detail-left p span {
    font-size: 1.5rem;
  }

  #content-work-detail-right {
    grid-template-columns: 1fr 1fr;
    padding: 2rem;
  }
}

@media (max-width: 1100px) {
  #content-work-left {
    margin: 0;
  }
}

@media screen and (max-width: 1000px) {
  #content-work-left {
    display: none;
  }

  .tech-item-container {
    font-size: 0.9rem;
  }

  .tech-icon {
    height: 40px;
    width: 40px;
  }
}

@media screen and (max-width: 700px) {
  #content-work-title-container h2 {
    font-size: 4rem;
  }
}

@media screen and (max-width: 550px) {
  #content-work-detail-container-inner {
    flex-direction: column;
  }
  #content-work-detail-container-inner > * {
    height: 50%;
    width: 100%;
  }
  #content-work-detail-left p {
    font-size: 0.7rem;
    line-height: 1rem;
  }
  #content-work-detail-right {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 0.5rem 1rem 3rem 1rem;
    font-size: 0.5rem;
  }

  .tech-item-container {
    font-size: 0.7rem;
  }
  .y-divider {
    display: none;
  }
  .x-divider {
    display: block;
  }
  .work-descriptor {
    display: none;
  }
}

/* --------------------------THEME STUFF --------------------------------------- */

.nav-work-theme .main-nav-side {
  border-right: 2px solid rgb(0, 0, 0);
}

.nav-work-theme #nav-background {
  background: white;
  opacity: 1;
}

.nav-work-theme .nav-container-active {
  background: #000000;
}

.nav-work-theme .main-nav-item-container {
  border-bottom: 2px solid rgb(0, 0, 0);
}

.nav-work-theme .nav-container-active .nav-icon {
  color: #04e762 !important;
}

/* .nav-work-theme .active-indicator {
  background: rgb(0, 0, 0);
} */

.nav-work-theme #switch-button {
  background: black;
}
