
#content-info-container {
    z-index: 2;
    /* background: black; */
    height: 100%;
    width: 100%;
    display: flex;
    overflow: hidden;
}



.mountain-background {
    animation: slide-in-bottom 1s ease-in-out .3s 1 forwards;
    position: absolute;
    width: 100%;
    filter: grayscale() opacity(.2);
    z-index: 0;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
}

#info-left-container {
    max-width: 600px;
    overflow: hidden;
    z-index: 1;
}

#info-left-background {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 43% 50%;
    filter: grayscale();
    /* transform: translateX(-100%); */
    right: 100%;
    opacity: 0;
    animation: slide-in-left .6s ease-in-out .3s 1 forwards;
}

#info-right-container {
    position: relative;
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    z-index: 1;
}



.info-right-child {
    position: relative;
    min-height: 50%;
    overflow: hidden;
}

#info-right-top-container {
    position: relative;
    background: #04E762;  
    background: linear-gradient(-45deg, #04E762, rgb(50, 164, 199));  
    transform: translateY(-100%);
    opacity: 0;
    animation: slide-in-top .6s ease-in-out .3s 1 forwards;
    display: flex;
    justify-content: center;
}

#info-right-top-container > h2 {
    font-size: 12rem;
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
    transform: translateY(-50%);
    z-index: 0;
    user-select:none;
}

#info-right-top-container > h2[id='bio-big-text'] {
    font-size: 20rem;
}

#info-right-bottom-container section {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    font-size: 1.3rem;
}


#info-right-bottom-container {
    transform: translateY(100%);
    animation: slide-in-bottom 1s ease-in-out .3s 1 forwards;
}

#info-bio {
    padding: 100px;
}

#info-bio h2 {
    font-size: 3rem;
}


#info-bio p {
    line-height: 28px;
}

#info-bio p span {
    font-family: 'Cabin Sketch', cursive;
    font-size: 2.5rem;
    color: #04E762;
    font-weight: bolder;
    line-height: 0px;
}

.contact-option-container {
    position: relative;
    background: rgba(255, 255,255, .1);
    height: 100%;
    width: 100%;
    display: flex;
    border: 4px solid white;
}

.contact-option-item-container {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: white;
    transition: all .3s;
    cursor: pointer;
    overflow: hidden;
}

.contact-option-container hr {
    height: 80%;
    align-self: center;
    border: 1px solid rgb(255, 255, 255);
}

.contact-option-item-container p {
    position: absolute;
    text-transform: uppercase;
    margin-bottom: 20px;
    bottom: 0px;
    transform: translateY(calc(100% + 20px));
    color: #04E762;
    font-size: 2rem;
    transition: all .5s;
}

.contact-icon {
    height: 100px;
    /* THIS IS THE ICON */
    color: #04E762;
    color: rgb(255, 255, 255);
    transition: all .4s;
    z-index: 1;
}

.contact-option-item-container:hover {
    flex-grow: 1.05;
}

.contact-option-item-container:hover p{
    opacity: 1;
    transform: translateY(0);
}


.contact-option-item-container:hover .contact-icon {
    filter: drop-shadow(0px 0px 30px rgb(0, 0, 0));
    transform: rotate(5deg) scale(1.1);
    /* color: #04E762; */
}

#email-notification {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 100%);
    background: rgba(0, 0, 0, .9);
    border: 2px solid #04E762;
    border: 2px solid #04E762;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    z-index: 1;
    color:rgb(110, 110, 110);
    transition: .7s all;
}

#email-note-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#email-notification span{
    color: white;
    margin-right: 5px; 
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: .5rem;
}

#email-note-icon {
    min-height: 40px;
    min-width: 40px;
    margin-right: .5rem;
    color: #04E762;
}

#email-notification.show-email-note {
    transform: translate(-50%, -50%);
}
/* ==============THEME STUFF!========================================= */

.info-section-background {
    background: black;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 1;  
}

.nav-info-theme .nav-active-indicator {
    background: black;
    background: rgb(50, 164, 199);
    background: #04E762;
}

.nav-info-theme #nav-background {
    background: black;
    opacity: 1;
}
  
.nav-info-theme .main-nav-side{
    border-right: 2px solid #04E762;
    border-right: 2px solid white;
    justify-content: flex-end;
}
.nav-info-theme .main-nav-item-container {
    border-bottom: 2px solid #04E762;
    border-bottom: 2px solid white;
}

.nav-info-theme .nav-icon{
    color: rgb(255, 255, 255);
    color: #04E762;
}

.nav-info-theme #mobile-nav {
    background: white;
    color: black
}

.nav-info-theme #cake {
    background: black;
}

.nav-info-theme #cake::before, .nav-info-theme #cake::after {
    background: black;
}

.nav-info-theme #mobile-nav-button::before {
    box-shadow: 50px 25px 0 25px rgb(255, 255, 255);
}
  
.nav-info-theme #mobile-nav-button::after {

    box-shadow: 50px -25px 0 25px rgb(255, 255, 255);
}


/* =========================MOBILE STUFF===============================*/

@media screen and (max-width: 1500px) {
    #info-left-container {
        max-width: 450px;
    }
    
    #info-right-top-container > h2 {
        font-size: 8rem;
    }
    
    #info-right-top-container > h2[id='bio-big-text'] {
        font-size: 20rem;
    }

    #info-switch-button {
        height: 70px;
        width: 70px;
        font-size: .75rem;
    }

    #info-switch-button .switch-arrow {
        font-size: 1.5rem;
    }

    #info-bio {
        padding: 100px 20px;
    }
    #info-bio p {
        line-height: 25px;
    }
    #info-bio p span {
        font-size: 1.75rem;
    }
}

@media screen and (max-width: 1000px) {
    #info-left-container {
        display: none;
    }

    #info-right-top-container > h2 {
        font-size: 5rem;
    }
    
    #info-right-top-container > h2[id='bio-big-text'] {
        font-size: 12rem;
    }
    #info-bio p {
        line-height: 25px;
        font-size: 1rem;
    }
    #info-bio p span {
        font-size: 1.75rem;
    }
    .contact-option-item-container p {
        font-size: 1.5rem;
    }
    .contact-icon {
        height: 75px;
        
    }
}

@media screen and (max-width: 500px) {
    #info-left-container {
        display: none;
    }

    #info-right-top-container > h2 {
        font-size: 5rem;
    }
    
    #info-right-top-container > h2[id='bio-big-text'] {
        font-size: 12rem;
    }
    #info-bio p {
        line-height: 15px;
        font-size: .7rem;
    }
    #info-bio p span {
        font-size: 1.2rem;
    }
    .contact-option-item-container p {
        font-size: 1rem;
    }
    .contact-icon {
        height: 50px;
        
    }
}