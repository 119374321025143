* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

@keyframes slide-in-left {
  from {
    /* transform: translateX(-100%); */
    right: 100%;
    opacity: 0;
  }
  to {
    /* transform: translateX(0%); */
    right: 0%;
    opacity: 1;
  }
}

@keyframes slide-in-right {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes slide-in-top {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes slide-in-bottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

li {
  list-style-type: none;
}

.title {
  font-family: "Cabin Sketch", cursive;
  font-weight: bolder;
  font-size: 2rem;
  color: rgb(255, 255, 255);
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Cabin Sketch", cursive;
  font-weight: bold;
  line-height: 1;
  color: white;
}

html {
  height: 100%;
}

#root {
  height: 100%;
}

.default {
  height: 100%;
}

body {
  position: relative;
  width: 100vw;
  height: 100% !important;
  background: rgb(49, 49, 49);
  overflow: hidden;
}

main {
  position: relative;
  height: 100% !important;
  width: calc(100vw - 100px);
  left: 100px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.main-nav-side {
  position: fixed;
  left: 0;
  top: 0;
  height: 100% !important;
  width: 100px;
  z-index: 3;
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
  border-right: 2px solid #04e762;
  transition: background 0.5s, border 0.5s;
}

#nav-background {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background: rgb(63, 63, 63);
  opacity: 0.5;
  z-index: 2;
  transition: 300ms all;
}

.main-nav-item-container {
  position: relative;
  width: 100px;
  height: 100px;
  border-bottom: 2px solid #04e762;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 3;
  transition: border 0.5s, color 0.5s, background 0.5s;
}

/* .main-nav-item-container:hover {
  background: rgb(247, 247, 247);
} */

.nav-container-active {
  background: white;
}

.nav-container-active .nav-icon {
  color: rgb(61, 61, 61) !important;
}

.main-nav-item-container:hover .nav-icon {
  transform: scale(2.2) rotate(5deg);
}

.main-nav-item-container .nav-icon {
  transform: scale(2);
  transition: 300ms all;
}

.nav-indicator {
  position: absolute;
  right: -15px;
  height: 95%;
  width: 4px;
  border-radius: 2px;
  background: white;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.active-indicator {
  background: #04e762;
}

/* .main-nav-item-container:first-child {
  border-top: 2px solid #04E762;
} */

.content-child-container {
  z-index: 1;
  top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
}

.alt-section-background {
  width: 100%;
  height: 100%;
  opacity: 1;
}

/* ========================MOBILE NAV===============================*/

#mobile-nav-deactivate-background {
  position: fixed;
  height: 100%;
  width: 100%;
  background: black;
  opacity: 0;
  z-index: 4;
  transition: 0.7s opacity;
  left: 100%;
}

#mobile-nav-deactivate-background.show-mobile-nav-back {
  left: 0%;
  opacity: 0.7;
}

#mobile-nav {
  display: none;
  position: fixed;
  z-index: 5;
  background: black;
  height: 100%;
  width: 50%;
  color: white;
  align-items: center;
  transition: 0.7s all;
  left: 100%;
  padding: 1rem;
}

#mobile-nav ul {
  width: 100%;
  position: relative;
}

#mobile-nav li {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid rgb(160, 160, 160);
  font-size: 2rem;
  font-weight: 200;
  cursor: pointer;
  transition: 0.3s all;
}

#mobile-nav li:last-child {
  border-bottom: 1px solid rgb(160, 160, 160);
}

#mobile-nav .mobile-active {
  color: #04e762;
}

#mobile-nav.show-nav {
  left: 50%;
}

#mobile-nav-button {
  position: absolute;
  height: 100px;
  width: 50px;
  left: -50px;
  background: inherit;
  border: none;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

#mobile-nav-button::before,
#mobile-nav-button::after {
  content: "";
  position: absolute;
  background: transparent;
  height: 50px;
  width: 50px;
  left: 0px;
  transition: 0.7s all;
  pointer-events: none;
}

#mobile-nav-button::before {
  top: -50px;
  border-bottom-right-radius: 50%;
  box-shadow: 50px 25px 0 25px black;
}

#mobile-nav-button::after {
  bottom: -50px;
  border-top-right-radius: 50%;
  box-shadow: 50px -25px 0 25px black;
}

#cake {
  position: absolute;
  height: 4px;
  width: 30px;
  background: white;
  z-index: 2;
  border-radius: 2px;
  transition: 0.3s all;
}

#cake::before,
#cake::after {
  position: absolute;
  content: "";
  background: white;
  height: 4px;
  width: 30px;
  left: 0;
  border-radius: 2px;
  transform: translate(0%) rotate(0deg);
  transition: 0.3s all;
}

#cake::before {
  top: -8px;
}

#cake::after {
  bottom: -8px;
}

.show-nav #cake {
  width: 0;
}

.show-nav #cake::before,
.show-nav #cake::after {
  transform: translate(-50%) rotate(0deg);
}

.show-nav #cake::before {
  top: 0px;
  transform: translate(-50%) rotate(-45deg);
}

.show-nav #cake::after {
  bottom: 0px;
  transform: translate(-50%) rotate(45deg);
}

/* ========================SWITCH BUTTON===============================*/

#switch-button {
  cursor: pointer;
  border: 3px solid white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background: rgb(50, 164, 199);
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(255, 255, 255);
  font-weight: 900;
  font-size: 1rem;
  opacity: 0;
  animation: fade-in 0.6s ease-in-out 1s 1 forwards;
  overflow: hidden;
}

#switch-button:hover {
  box-shadow: 0 0 0 3px white;
}

#switch-button:hover {
  transform: translate(-50%, -50%) rotate(15deg);
}

.switch-button-inner-container {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.switch-button-inner-container #switch-button-name {
  position: relative;
  font-size: 0.8rem;
}

#switch-button-name span {
  display: block;
  position: absolute;
  font-size: 0.6rem;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.5);
  white-space: nowrap;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.switch-arrow {
  position: absolute;
  top: 0px;
  color: rgb(255, 255, 255);
  filter: drop-shadow(inset 0 0 5px black);
}

.alt-switch-arrow {
  transform: rotate(180deg);
  top: auto;
  bottom: 0px;
}

/* =========================BACKGROUND STUFF===============================*/

#content-background-dynamic {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 0.1;
  background: url("https://images.unsplash.com/photo-1552084117-56a987666449?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2134&q=80"),
    linear-gradient(-45deg, #04e762, rgb(50, 164, 199));
  /* background-blend-mode: darken; */
  filter: grayscale(1);
  z-index: 0;
}

@media (max-width: 1100px) {
  #content-background-dynamic {
    width: 125%;
  }
}

@media (max-width: 875px) {
  #content-background-dynamic {
    width: 200%;
  }
}

@media (max-width: 650px) {
  #content-background-dynamic {
    width: 250%;
  }

  #switch-button {
    height: 75px;
    width: 75px;
    font-size: 0.7rem;
  }
  .switch-button-inner-container #switch-button-name span {
    visibility: hidden;
  }
}

@media (max-width: 450px) {
  #content-background-dynamic {
    width: 400%;
  }
}

/* =========================MOBILE STUFF===============================*/

@media screen and (max-width: 1100px) {
  main {
    left: 0;
    width: 100%;
  }
  .main-nav-side {
    display: none;
  }

  #mobile-nav {
    display: flex;
  }
}
